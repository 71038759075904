import * as React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/core';
import { Link } from 'gatsby';

import SEO from '../base/Seo';
import Logo from '../base/Logo';
import base from '../../styles/base';
import normalize from '../../styles/normalize';

import { route } from '../../constants/app';
import { color } from '../../constants/styles';

interface AppSplashProps {
  title?: string;
  description?: string;
  image?: string;
}

const Container = styled(Link)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: ${color.light};
`;

const AppMain = styled.main`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const AppSplash: React.SFC<AppSplashProps> = props => {
  const { title, description } = props;
  return (
    <Container to={route.works}>
      <SEO title={title} description={description} />
      <Global
        styles={css`
          ${normalize}
          ${base}
        `}
      />
      <AppMain>
        <h1>
          <Logo width="160" />
        </h1>
      </AppMain>
    </Container>
  );
};

export default AppSplash;
